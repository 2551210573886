<template>
  <div class="Awarp">
    <dashCard
      class="photovoltaicBox6"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('photovoltaicBox6_count','电站发电量统计', getZEdata)}}</template>
      <template slot="aside">
        <div class="type-group">
          <span
            class="pick-type"
            v-for="(item, idx) in timetType"
            :key="idx"
            @click="changeType(item.code)"
          >
            <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
              item.name
            }}</font>
          </span>
        </div>
        <a-date-picker
          v-if="searchObj.TYPE == '按天'"
          :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-week-picker
          v-if="searchObj.TYPE == '按周'"
          :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-month-picker
          v-if="searchObj.TYPE == '按月'"
          :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
        />
        <a-date-picker
          v-if="searchObj.TYPE == '按年'"
          :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
          v-model="searchObj.VALUE"
          @change="onChange"
          mode="year"
          :open="panelOpen"
          @openChange="openChange"
          @panelChange="panelChange"
          format="YYYY"
        />
      </template>
      <div>
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px;height: fit-content;"
          @change="handleChange"
          v-if="$store.state.currentSelectDeptInfo.DEPTTYPE === '站点'"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in eqlist"
            :key="key"
            :value="item.assetNumber"
          >
            {{ item.assetName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="card-content1" style="display: flex;justify-content: space-between;">
        
        <div class="content-pass" style="display:flex;margin-top: 20px;">
          <div v-if="searchObj.TYPE == '按年'">{{commonLangHandler('photovoltaicBox6_thisYear','今年总发电量', getZEdata)}}</div>
          <div v-if="searchObj.TYPE == '按月'">{{commonLangHandler('photovoltaicBox6_thisMonth','本月总发电量', getZEdata)}}</div>
          <div v-if="searchObj.TYPE == '按周'">{{commonLangHandler('photovoltaicBox6_thisWeek','本周总发电量', getZEdata)}}</div>
          <div v-if="searchObj.TYPE == '按天'">{{commonLangHandler('photovoltaicBox6_today','今天总发电量', getZEdata)}}</div>
          <div>{{ this.detailInfo.total }}<font>kWh</font></div>
          <div>
            <i
                :class="iconClass"
            >
            </i>
            <font
                style="
                font-size: 12px;
                font-weight: 400;
                color: #9f9f9f;
                line-height: 20px;
                margin-right: 6px;
              "
              >{{ detailInfo.comparePercent1 == 100 ? "NaN":Math.abs(detailInfo.comparePercent1)  }} %</font
            >
            <font
              style="
                font-size: 12px;
                font-weight: 400;
                color: #9f9f9f;
                line-height: 20px;
                margin-right: 6px;
              "
              >{{commonLangHandler('photovoltaicBox6_yoy','较上年', getZEdata)}}</font
            >
          </div>
        </div>

      </div>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <photovoltaicBox6
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "photovoltaicBox6",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getlist();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      iconClass:"",
      eqlist: [],
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中
      gridLeft: [],
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },

        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      itemList: [
        {
          code: "光伏逆变器",
          name: "光伏逆变器",
        },
      ],
      panelOpen: false,
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
        ITEM: "", //选中的项目
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      // colorList: ["#3366FF","#B4D0FF","#51A34B"],
      colorList: ["#1191E6", "#7ED6C6", "#1E6119", "#160C9C"],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    getlist() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type:"光伏逆变器",
          site:true
        },
      }).then((res) => {
        
        if (!res.data.data) {
          this.searchObj.ITEM = "";
          this.eqlist = []
        } else {
          this.eqlist = res.data.data;
          this.searchObj.ITEM = "";
          this.searchObj.ITEM = this.eqlist[0]
            ? this.eqlist[0].assetNumber
            : "";
        }
        this.getData();
      });
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
          .toString()
          .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {

      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        assetNumber: this.searchObj.ITEM,
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/generation/statistics",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.detailInfo = {};
          var chart1 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart1 = chart1;
          let newarr = [];
          this.detailInfo.chart1.seriesData.forEach((item) => {
            item.value.forEach((val) => {
              newarr.push(val);
            });
          });
          if (this.getNull(newarr) == newarr.length) {
            this.gridLeft = [0, 0, 0, 50];
          } else {
            this.gridLeft = [0, 0, 0, 0];
          }
          this.detailInfo.total = result.total;
          this.detailInfo.comparePercent1 = result["YoY"];
          if(this.detailInfo.comparePercent1 >= 0) {
            this.iconClass="lowcode icon-shang shangColor";
          }else{
            this.iconClass="lowcode icon-xia xiaColor";
          }
          let lang = sessionStorage.getItem("locale") || 'CH'
            if(lang=='EN'){
            this.gridLeft = [0, 0, 0, 120];
            }
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getNull(newarr) {
      return newarr.reduce((total, currentValue) => {
        if (
            currentValue === 0 ||
            JSON.parse(currentValue) === 0 ||
            typeof currentValue == "0"
        )
          return total + 1;
        return total;
      }, 0);
    },
    initEchart() {
      let vm = this;
      var { categoryData, seriesData } = this.detailInfo.chart1;

      var series = [];
      seriesData.forEach((item, idx) => {
        var obj = {
          type: "line",
          animationDurationUpdate: 2000,
          animationEasingUpdate: "quinticInOut",
          name: item.name,
          data: item.value,
          smooth: false,
          showSymbol: false,
          emphasis: {
            focus: "series",
          },
        };
        series.push(obj);
      });
      var option = {
        animation: true,
        layoutAnimation: false,
        grid: {
          left: "3%",
          right: "3%",
          bottom: "7%",
          top: "10%",
          containLabel: true,
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            height: 20,
            bottom: 0,
          },
          {
            start: 0,
            end: 100,
            height: 20,
            bottom: 10,
          },
        ],
        legend: {
          right: 0,
          top: "0%",
          orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          // formatter: function(params, ticket, callback) {
          //   let str = ""
          //   if(vm.searchObj.TYPE === "按年"){
          //     str = params[0].name.split("-")[1] + "月" + "<br/>"
          //   }else{
          //     str = params[0].name + "<br/>"
          //   }
          //   params.forEach(item=>{
          //     let a = 0
          //     if(Number(item.value)>9999){
          //       a = (item.value/10000).toFixed(2) + "万"
          //     }else{
          //       a = item.value
          //     }
          //     str += item.seriesName + "<span style='display: inline-block;margin-right: 20px'></span>" + a + "<br/>"
          //   })
          //   return str
          // }
        },
        xAxis: {
          type: "category",
          data: categoryData,
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
          },
        },
        yAxis: {
          name: this.commonLangHandler('photovoltaicBox6_powerGeneration','发电量（KWh）', this.getZEdata) ,
          axisTick: {
            show: false,
          },
          nameTextStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#9f9f9f",
            fontSize: 14,
            padding: this.gridLeft,
          },
          axisLine: {
            lineStyle: {
              color: "#1f1f1f",
            },
          },
          axisLabel: {
            margin:10,
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#696969",
            fontSize: 14,
            formatter(v) {
              v = v.toString();
              if (v >= 100000000000) {
                return v.substring(0, 5) / 10 + "亿";
              } else if (v >= 10000000000) {
                return v.substring(0, 4) / 10 + "亿";
              } else if (v >= 1000000000) {
                return v.substring(0, 3) / 10 + "亿";
              } else if (v >= 100000000) {
                return v.substring(0, 2) / 10 + "亿";
              } else if (v >= 10000000) {
                return v.substring(0, 4) + "万";
              } else if (v >= 1000000) {
                return v.substring(0, 3) + "万";
              } else if (v >= 100000) {
                return v.substring(0, 2) + "万";
              } else if (v >= 10000) {
                return v.substring(0, 2) / 10 + "万";
              } else if (v >= 1000) {
                return v;
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 7) / 10 + "亿";
              } else if (v <= -100000000000) {
                return "-" + v.substring(1, 6) / 10 + "亿";
              } else if (v <= -10000000000) {
                return "-" + v.substring(1, 5) / 10 + "亿";
              } else if (v <= -1000000000) {
                return "-" + v.substring(1, 4) / 10 + "亿";
              } else if (v <= -100000000) {
                return "-" + v.substring(1, 3) / 10 + "亿";
              } else if (v <= -10000000) {
                return "-" + v.substring(1, 5) + "万";
              } else if (v <= -1000000) {
                return "-" + v.substring(1, 4) + "万";
              } else if (v <= -100000) {
                return "-" + v.substring(1, 3) + "万";
              } else if (v <= -10000) {
                return "-" + v.substring(1, 3) / 10 + "万";
              } else if (v <= -1000) {
                return v;
              } else {
                return v;
              }
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              // type: "dashed",
              width: 0.5,
              color: "#bababa",
            },
          },
          // 控制数据参数最大值和最小值
          // interval: 10,
          // max: 100
        },
        // 控住柱状图样式
        series: series,
        color: this.colorList,
      };
      this.chartOption1= option;
      setTimeout(() => {
        this.$refs["chart1"].updateChartView();
      }, 500);
    },
  },
  mounted() {
    
    this.getOperateWidth();
    this.getlist();
  },
};
</script>

<style lang="less" scoped>
.photovoltaicBox6 {
  .card-content1 {
    .content-pass {
      // margin-top: 12px;
      height: inherit;
      box-sizing: border-box;
      display: inline-flex;
      flex-direction: column;
      margin-right: 10px;
      background: #fafafa;
      padding: 10px;
      border-radius: 10px;
      div {
        &:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 22px;
        }
        &:nth-child(2) {
          font-size: 32px;
          font-weight: 500;
          color: #1d2129;
          line-height: 40px;
          font {
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.45);
            line-height: 20px;
            margin-left: 6px;
          }
        }
        &:nth-child(3) {
          font-size: 12px;
          font-weight: 400;
          color: #9f9f9f;
          line-height: 20px;
          .percent-box {
            color: #0ca919;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-right: 6px;
            // display: flex;
            i {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
}
.shangColor {
  color: #f53f3f;
}

.xiaColor {
  color: #0ca919;
}
</style>
